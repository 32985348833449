import { connectInfiniteHits } from "react-instantsearch-dom";

export const SaleItemTable = ({ action, hits }: { action: any; hits: any }) => {
  if (!hits || !hits.length) {
    return <div>no results</div>;
  }

  return (
    <table>
      <thead>
        <tr>
          <th>title</th>
          <th>user</th>
          <th>description</th>
          <th>condition</th>
          <th>images</th>
          {action && <th>action</th>}
        </tr>
      </thead>
      <tbody>
        {hits.map((saleItem: any) => {
          const style: any = {};
          if (saleItem.status === "deleted") {
            style.backgroundColor = "#FF9999";
          }
          if (!saleItem?.images?.original && saleItem["images.original"]) {
            saleItem = {
              ...saleItem,
              images: { original: saleItem["images.original"] },
              userimages: {
                avatar_100x100: saleItem["userimages.avatar_100x100"],
              },
            };
          }
          return (
            <tr key={saleItem.id} style={style}>
              <td>{saleItem.title}</td>
              <td>{saleItem.username}</td>
              <td>{saleItem.description}</td>
              <td>{saleItem.condition}</td>
              <td>
                {saleItem.images.original.map((image: string) => {
                  return (
                    <a key={image} href={image} target="_blank">
                      <img
                        src={image}
                        height={100}
                        style={{ marginRight: 4 }}
                      />
                    </a>
                  );
                })}
              </td>
              {action && <td>{action(saleItem)}</td>}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export const InfiniteSaleItemTable = connectInfiniteHits(SaleItemTable);
