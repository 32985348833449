import style from "../styles/home.module.css";
import { Helmet } from "react-helmet";

const PublicHome = () => (
  <div>
    <Helmet>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
      <title>{"Offpanel"}</title>
      <meta name={"description"} content={"Offpanel is a marketplace for the comic collecting community to buy and sell comics with other collectors. Whether you’ve been collecting for decades or are new to the hobby, Offpanel is the best way to buy and sell comics because it’s built by collectors for collectors."} />
    </Helmet>
    <div
      className={style.wrapper}
      style={{ backgroundImage: "url('/.well-known/images/bg.jpg')" }}
    >
      <div className={style.hero}>
        <img className={style.logo} src="/.well-known/images/logo.png" />
        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <a href="https://apps.apple.com/us/app/offpanel-comic-marketplace/id1617929668">
            <img src="/.well-known/images/app_store.svg" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=co.offpanel.buttercup&hl=en_US&gl=US">
            <img src="/.well-known/images/google_play.png" width="150" />
          </a>
        </div>
      </div>
    </div>
  </div>
);
export default PublicHome;
