import { deleteDoc, doc, getFirestore, updateDoc } from "firebase/firestore";
import { useState } from "react";

const db = getFirestore();

const Category = ({
  category,
  currentCategoryId,
  setCurrentCategoryId,
}: {
  category: any;
  currentCategoryId: any;
  setCurrentCategoryId: any;
}) => {
  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState(category.name);
  const [searchTerms, setSearchTerms] = useState(category.searchTerms || "");

  const deleteCategory = async () => {
    if (confirm("You sure?")) {
      await deleteDoc(doc(db, "Category", category.id));
    }
  };

  const updateCategory = async (evt: any) => {
    evt.preventDefault();
    const categoryRef = doc(db, "Category", category.id);
    await updateDoc(categoryRef, {
      name,
      searchTerms,
    });
  };

  const slug = `${category.name} -- "${
    searchTerms ? searchTerms : "no search terms"
  }" : (${category.items?.length || 0})`;
  if (editMode) {
    return (
      <li key={category.id}>
        name:{" "}
        <input
          type="text"
          value={name}
          onChange={(evt) => setName(evt.target.value)}
        />{" "}
        search terms:{" "}
        <input
          type="text"
          value={searchTerms}
          onChange={(evt) => setSearchTerms(evt.target.value)}
        />
        <button style={{ marginLeft: 8 }} onClick={updateCategory}>
          save
        </button>
      </li>
    );
  } else {
    return (
      <li key={category.id}>
        {currentCategoryId === category.id ? (
          <b>{slug}</b>
        ) : (
          <a onClick={() => setCurrentCategoryId(category.id)}>{slug}</a>
        )}{" "}
        <a onClick={() => setEditMode(true)}>✎</a>{" "}
        <a
          style={{ color: "#DD8888", fontWeight: "bold" }}
          onClick={deleteCategory}
        >
          x
        </a>
      </li>
    );
  }
};

export default Category;
