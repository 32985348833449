import { useState } from "react";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { Link, Outlet } from "react-router-dom";
import "../styles/admin.css";
import { envVars } from "../util/env";

let firebaseConfig = {
  apiKey: "AIzaSyDpdu_qE0xwAgYKsgDA78vBj6nj02ssjDs",
  authDomain: "offpanel-a02fa.firebaseapp.com",
  projectId: "offpanel-a02fa",
  storageBucket: "offpanel-a02fa.appspot.com",
  messagingSenderId: "1056219258874",
  appId: "1:1056219258874:web:9f07652e45fd98c9950e43",
  measurementId: "G-2KHJNN9589",
};

if (envVars().ENV === "staging") {
  firebaseConfig = {
    apiKey: "AIzaSyAe11X4LmU0nvr0ryxXn_aKTS9FHqqrkoI",
    authDomain: "offpanel-staging.firebaseapp.com",
    projectId: "offpanel-staging",
    storageBucket: "offpanel-staging.appspot.com",
    messagingSenderId: "242239633162",
    appId: "1:242239633162:web:0d5b2f8562b00b52c86cf3",
    measurementId: "",
  };
}

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore();

const Admin = () => {
  const [user, setUser] = useState<any>(null);
  const [email, setEmail] = useState("");
  const [initalized, setInitialized] = useState(false);
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const signIn = (evt: any) => {
    evt.preventDefault();
    try {
      signInWithEmailAndPassword(auth, email, password);
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  };

  onAuthStateChanged(auth, async (user) => {
    if (user) {
      const userMeta = await getDoc(doc(db, "UserMeta", user.uid));
      if (userMeta?.data()?.admin) {
        setUser(user);
      } else {
        throw new Error("Unauthorized");
      }
    }
    setInitialized(true);
  });

  if (initalized) {
    if (!user) {
      return (
        <div style={{ margin: 20 }}>
          <form onSubmit={signIn}>
            {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
            <input
              type="text"
              value={email}
              onChange={(evt) => setEmail(evt.target.value)}
            />
            <br />
            <input
              type="password"
              value={password}
              onChange={(evt) => setPassword(evt.target.value)}
            />
            <br />
            <button>login</button>
          </form>
        </div>
      );
    } else {
      return (
        <div style={{ margin: 20 }}>
          <div style={{ marginBottom: 10 }}>
            <Link to="new_queue">New Queue</Link> |{" "}
            <Link to="categories">Categories</Link>
          </div>
          <Outlet />
        </div>
      );
    }
  }
  return null;
};

export default Admin;
