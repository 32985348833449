import style from "../styles/privacy.module.css";

const Terms = () => (
  <div className={style.privacypagewrapper}>
    <div className={style.privacywrapper}>
      <div className={style.privacy}>
        <div className={style.title}>TERMS OF USE</div>
        <br />
        <div>
          <b>WELCOME TO OFFPANEL.</b> By accessing or otherwise interacting with
          our servers, services, websites, mobile app ("App"), or any associated
          content/postings (together, "Offpanel"), you agree to these Terms of
          Use ("TOU"). You acknowledge and agree Offpanel is a private
          application owned and operated by Offpanel. If you are accessing or
          using Offpanel on behalf of a business, you represent and warrant to
          Offpanel that you have authority to accept the TOU on behalf of that
          business and that that business agrees to the TOU. If you do not agree
          to the TOU, you are not authorized to use Offpanel or download the
          App. We may modify the TOU at any time in our sole discretion. You are
          responsible for periodically checking for changes and are bound by
          them if you continue to use Offpanel. Our privacy policy
          (offpnel.co/privacy), and all other policies, site rules, and
          agreements referenced below or on Offpanel, are fully incorporated
          into this TOU, and you agree to them as well.
        </div>
        <br />
        <div>
          <b>LICENSE.</b> If you agree to the TOU and (1) are of sufficient age
          and capacity to use Offpanel and be bound by the TOU, or (2) use
          Offpanel on behalf of a business, thereby binding that business to the
          TOU, we grant you a limited, revocable, non-exclusive, non-assignable
          license to use Offpanel in compliance with the TOU; unlicensed use is
          unauthorized. You agree not to display, "frame," make derivative
          works, distribute, license, or sell, content from Offpanel, excluding
          postings you create. You grant us a perpetual, irrevocable, unlimited,
          worldwide, fully paid/sublicensable license to use, copy, display,
          distribute, and make derivative works from content you post.
        </div>
        <br />
        <div>
          <b>USE.</b> Unless licensed by us in a separate written or electronic
          agreement, you agree not to use or provide software (except our App
          and general purpose web browsers and email clients) or services that
          interact or interoperate with Offpanel, e.g. for downloading,
          uploading, creating/accessing/using an account, posting, flagging,
          emailing, searching, or mobile use. You agree not to copy/collect
          Offpanel content via robots, spiders, scripts, scrapers, crawlers, or
          any automated or manual equivalent (e.g., by hand). Misleading,
          unsolicited, and/or unlawful postings/communications/accounts are
          prohibited, as is buying or selling accounts. You agree not to post
          content that is prohibited by any of Offpanel's policies or rules
          referenced above ("Prohibited Content") You agree not to abuse
          Offpanel's flagging or reporting processes. You agree not to collect
          Offpanel user information or interfere with Offpanel. You agree we may
          moderate Offpanel access/use in our sole discretion, e.g., by
          blocking, filtering, re-categorizing, re-ranking, deleting, delaying,
          holding, omitting, verifying, or terminating your
          access/license/account. You agree (1) not to bypass said moderation,
          (2) we are not liable for moderating or not moderating, and (3)
          nothing we say or do waives our right to moderate, or not. Unless
          licensed by us in a separate written or electronic agreement, you
          agree not to (i) rent, lease, sell, publish, distribute, license,
          sublicense, assign, transfer, or otherwise make available Offpanel or
          our application programming interface ("API"), (ii) copy, adapt,
          create derivative works of, decompile, reverse engineer, translate,
          localize, port or modify the App, the API, any website code, or any
          software used to provide Offpanel, (iii) combine or integrate Offpanel
          or the API with any software, technology, services, or materials not
          authorized by us, (iv) circumvent any functionality that controls
          access to or otherwise protects Offpanel or the API, or (v) remove or
          alter any copyright, trademark or other proprietary rights notices.
          You agree not to use Offpanel or the API in any manner or for any
          purpose that infringes, misappropriates, or otherwise violates any
          intellectual property right or other right of any person, or that
          violates any applicable law. You further agree that repeated
          violations of the USE section will irreparably harm and entitle us to
          injunctive or equitable relief, in addition to monetary damages.
        </div>
        <br />
        <div>
          <b>DISCLAIMER & LIABILITY.</b> To the full extent permitted by law,
          Offpanel, Inc., and its officers, directors, employees, agents,
          licensors, affiliates, and successors in interest ("Offpanel
          Entities") (1) make no promises, warranties, or representations as to
          Offpanel, including its completeness, accuracy, availability,
          timeliness, propriety, security or reliability; (2) provide Offpanel
          on an "AS IS" and "AS AVAILABLE" basis and any risk of using Offpanel
          is assumed by you; (3) disclaim all warranties, express or implied,
          including as to accuracy, merchantability, fitness for a particular
          purpose, and non-infringement, and all warranties arising from course
          of dealing, usage, or trade practice; and (4) disclaim any liability
          or responsibility for acts, omissions, or conduct of you or any party
          in connection with Offpanel. Offpanel Entities are NOT liable for any
          direct, indirect, consequential, incidental, special, punitive, or
          other losses, including lost profits, revenues, data, goodwill, etc.,
          arising from or related to Offpanel, and in no event shall such
          liability exceed $100. Some jurisdictions restrict or alter these
          disclaimers and limits, so some may not apply to you.
        </div>
        <br />
        <div>
          <b>CLAIMS & INDEMNITY.</b> Any claim, cause of action, demand, or
          dispute arising from or related to Offpanel ("Claims") will be
          governed by the internal laws of New York, without regard to conflict
          of law provisions, except to the extent governed by US federal law.
          Any Claims will be exclusively resolved by courts in New York, NY
          (except we may seek preliminary or injunctive relief anywhere). You
          agree to (1) submit to the personal jurisdiction of courts in New
          York, NY; (2) indemnify and hold Offpanel Entities harmless from any
          Claims, losses, liability, or expenses (including attorneys' fees)
          that arise from a third party and relate to your use of Offpanel; and
          (3) be liable and responsible for any Claims we may have against your
          officers, directors, employees, agents, affiliates, or any other
          party, directly or indirectly, paid, directed or controlled by you, or
          acting for your benefit.
        </div>
        <br />
        <div>
          <b>MISC.</b> Unless you have entered into a separate written or
          electronic agreement with us that expressly references the TOU, this
          is the exclusive and entire agreement between us and you, and
          supersedes all prior and contemporaneous understandings, agreements,
          representations, and warranties, both written and oral. Our actions or
          silence toward you or anyone else does not waive, modify, or limit the
          TOU or our ability to enforce it. The USE, and CLAIMS & INDEMNITY
          sections survive termination of the TOU, and you will remain bound by
          those sections. If a TOU term is unenforceable, it shall be limited to
          the least extent possible and supplemented with a valid provision that
          best embodies the intent of the parties. The English version of the
          TOU controls over any translations.
        </div>
      </div>
      <div className="bottomspacer"></div>
    </div>
  </div>
);
export default Terms;
