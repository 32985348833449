interface EnvVars {
  TYPESENSE_API_KEY: string;
  TYPESENSE_HOST: string;
  ENV: "staging" | "production";
}

export const envVars = (): EnvVars => {
  if (
    window.location.href.startsWith("https://offpanel-staging") ||
    window.location.href.startsWith("http://localhost")
  ) {
    return {
      TYPESENSE_API_KEY: "zsmCrpcLTgkeRxvd0SRpMd805pXYj55b",
      TYPESENSE_HOST: "7u6tbgds3f4vknjip-1.a1.typesense.net",
      ENV: "staging",
    };
  } else {
    return {
      TYPESENSE_API_KEY: "vkd4SO3zlF1r8exvwUKagXmXFn9TSlMl",
      TYPESENSE_HOST: "879jugdft45qbo3cp-1.a1.typesense.net",
      ENV: "production",
    };
  }
};
