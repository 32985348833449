import {useState} from 'react';
import { connectInfiniteHits, connectSearchBox } from "react-instantsearch-dom";
import {Row, Col} from "react-grid-system";
import InfiniteScroll from 'react-infinite-scroll-component';
import { formatPriceForDisplay } from '../util/display_formatting';

const MySearchBox = connectSearchBox(({ currentRefinement, refine }) => {
    return (
      <input
        type="text"
        placeholder="Search for something..."
        value={currentRefinement}
        onChange={e => {
          refine(e.target.value);
        }}
        style={{
          width: '100%',
          padding: 10,
          borderRadius: 10,
          borderColor: 'lightgrey',
          borderStyle: 'solid',
          boxSizing: 'border-box',
          marginTop: 30,
          marginBottom: 30,
          fontSize: 20,
          outline: 'none',
        }}
      />
    );
  });

const ProfileInfiniteHits = ({ hits, hasMore, refineNext }: { hits: any, hasMore: any, refineNext: any }) => {

    const [showSearchBox, setShowSearchBox] = useState(false);

    if (hits && hits.length > 0 && !showSearchBox) {
        setShowSearchBox(true);
    }

    const getThumbUriFromSearchResultSaleItem = (saleItem: any) => {
        const uid = saleItem['images.original'][0];
        const fileNameArray = decodeURIComponent(uid).split('?')[0].split('/');
        const fileName = fileNameArray[fileNameArray.length - 1];
        if (saleItem['images.scaled.600x600.' + fileName]) {
          return saleItem['images.scaled.600x600.' + fileName];
        }
        return uid;
      };
    
    const onEndReached = () => hasMore && refineNext();

    return (
      
        <InfiniteScroll
            dataLength={hits.length}
            next={onEndReached}
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
            endMessage={<div style={{marginTop: 20, marginBottom: 40}}>
                <p style={{ textAlign: 'center' }}>

                <img src={"/.well-known/images/offpanel-light.png"} width={120} />
                </p>
                </div>
            }
            style={{flex: 1}}
        >
            {showSearchBox && <MySearchBox />}
            <Row>
                {hits.map((saleItem: any) => {
                        return (<Col xs={6} md={4} lg={3} key={saleItem.id} style={{marginBottom: 30}}>
                                    <a href={`/saleitem/${saleItem.id}`}>
                                        <div style={{
                                            backgroundColor: '#F5F5F5',
                                            width: '100%',
                                            aspectRatio: '3/4',
                                            backgroundImage: `url(${getThumbUriFromSearchResultSaleItem(saleItem)})`,
                                            backgroundPosition: 'center',
                                            backgroundSize: 'cover',
                                            backgroundRepeat: 'no-repeat',
                                            borderRadius: 10,
                                            position: 'relative',
                                        }}>
                                            <div style={{
                                                color: 'white',
                                                backgroundColor: 'black',
                                                display: 'inline-block',
                                                padding: 10,
                                                margin: 10,
                                                borderRadius: 10,
                                                fontWeight: '600',
                                                position: 'absolute',
                                                bottom: 0
                                            }}>
                                                {saleItem.title}
                                            </div>
                                            <div style={{
                                                color: 'white',
                                                backgroundColor: 'black',
                                                display: 'inline-block',
                                                padding: 10,
                                                margin: 10,
                                                borderRadius: 10,
                                                fontWeight: '600',
                                                position: 'absolute',
                                                top: 0,
                                                right: 0,
                                            }}>
                                                {formatPriceForDisplay(saleItem.price)}
                                            </div>
                                        </div>
                                    </a>
                                </Col>);
                    })}
            </Row>
        </InfiniteScroll>
  );
};

export default connectInfiniteHits(ProfileInfiniteHits);
