import { useEffect, useState } from "react";
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getFirestore,
  query,
  updateDoc,
  onSnapshot,
  serverTimestamp,
  getDoc,
} from "firebase/firestore";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import {
  SearchBox,
  InstantSearch,
  connectStateResults,
} from "react-instantsearch-dom";

import {
  InfiniteSaleItemTable,
  SaleItemTable,
} from "../components/sale_item_table";
import Category from "../components/category";
import { envVars } from "../util/env";

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: envVars().TYPESENSE_API_KEY,
    nodes: [
      {
        host: envVars().TYPESENSE_HOST,
        port: 443,
        protocol: "https",
      },
    ],
  },
  additionalSearchParameters: {
    query_by: "title",
  },
});
const searchClient = typesenseInstantsearchAdapter.searchClient;

const db = getFirestore();

const Categories = () => {
  const [categories, setCategories] = useState<any>({});
  const [newCategoryName, setNewCategoryName] = useState("");
  const [currentCategoryId, setCurrentCategoryId] = useState("");

  const fetchCategories = () => {
    const q = query(collection(db, "Category"));
    onSnapshot(q, (cats) => {
      setCategories({});
      cats.forEach((cat) => {
        setCategories((obj: any) => {
          return {
            ...obj,
            [cat.id]: { id: cat.id, ...cat.data() },
          };
        });
      });
    });
  };

  const createNewCategory = async (evt: any) => {
    evt.preventDefault();
    if (newCategoryName) {
      const newCat = await addDoc(collection(db, "Category"), {
        status: "pending",
        name: newCategoryName,
        items: [],
        ctime: serverTimestamp(),
      });
      setNewCategoryName("");
      setCurrentCategoryId(newCat.id);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const AddItemToCategoryAction = (item: any) => {
    const addItemToCategory = async () => {
      const categoryRef = doc(db, "Category", currentCategoryId);
      const newItem = { ...item };
      Object.keys(newItem).forEach((key) => {
        const val = newItem[key];
        // typesense adds a bunch of bullshit
        if (val == null || key.startsWith("_")) {
          delete newItem[key];
        } else if (typeof val === "object") {
          // removing undefineds, this happens with people with no avatars
          Object.keys(val).forEach(
            (key) => val[key] == null && delete val[key]
          );
        }
      });

      const categorySnap = await getDoc(categoryRef);
      if (categorySnap.exists()) {
        await updateDoc(categoryRef, {
          status: "active",
          items: [newItem, ...categorySnap.data().items],
        });
      } else {
        alert("that didn't work");
      }
    };
    return (
      <button onClick={addItemToCategory}>
        add to {categories[currentCategoryId]?.name}
      </button>
    );
  };

  const RemoveItemFromCategoryAction = (item: any) => {
    const removeItemFromCategory = async () => {
      if (categories[currentCategoryId].items.length <= 1) {
        alert("You can't remove the last item in a category.");
      } else {
        const categoryRef = doc(db, "Category", currentCategoryId);
        await updateDoc(categoryRef, { items: arrayRemove(item) });
      }
    };
    return (
      <button onClick={removeItemFromCategory}>
        remove from {categories[currentCategoryId].name}
      </button>
    );
  };

  const Results = connectStateResults(({ searchState }) =>
    searchState && searchState.query ? (
      <InfiniteSaleItemTable action={AddItemToCategoryAction} />
    ) : null
  );

  return (
    <div>
      <form onSubmit={createNewCategory}>
        <input
          type="text"
          value={newCategoryName}
          onChange={(evt) => setNewCategoryName(evt.target.value)}
        />
        <button style={{ marginLeft: 8 }}>new</button>
      </form>
      <ul>
        {Object.values(categories).map((category: any) => (
          <Category
            category={category}
            currentCategoryId={currentCategoryId}
            setCurrentCategoryId={setCurrentCategoryId}
          />
        ))}
      </ul>
      {currentCategoryId && (
        <div>
          {categories[currentCategoryId]?.items && (
            <div>
              <h3>Items in {categories[currentCategoryId].name}</h3>
              <div>
                <SaleItemTable
                  hits={categories[currentCategoryId].items}
                  action={RemoveItemFromCategoryAction}
                />
              </div>
            </div>
          )}
          {categories[currentCategoryId]?.name && (
            <h3>
              Search to add new items to {categories[currentCategoryId]?.name}
            </h3>
          )}
          <div>
            <InstantSearch searchClient={searchClient} indexName="saleitem">
              <SearchBox />
              <Results />
            </InstantSearch>
          </div>
        </div>
      )}
    </div>
  );
};
export default Categories;
