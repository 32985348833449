import { useEffect, useState } from "react";
import {
  getFirestore,
  query,
  collection,
  onSnapshot,
  orderBy,
  limit,
  doc,
  updateDoc,
} from "firebase/firestore";
import { SaleItemTable } from "../components/sale_item_table";

const db = getFirestore();

const NewQueue = () => {
  const [saleItems, setSaleItems] = useState<any>([]);

  const fetchSaleItems = () => {
    const q = query(
      collection(db, "SaleItem"),
      orderBy("ctime", "desc"),
      limit(50)
    );
    onSnapshot(q, (saleItems) => {
      setSaleItems([]);
      saleItems.forEach((cat) => {
        setSaleItems((arr: any) => {
          return [...arr, { id: cat.id, ...cat.data() }];
        });
      });
    });
  };

  const toggleAction = (item: any) => {
    const setStatus = async (status: any) => {
      if (confirm("You sure?")) {
        const saleItemRef = doc(db, "SaleItem", item.id);
        await updateDoc(saleItemRef, { status });
      }
    };
    if (item.status === "deleted") {
      return <button onClick={() => setStatus("active")}>undelete</button>;
    } else {
      return <button onClick={() => setStatus("deleted")}>delete</button>;
    }
  };

  useEffect(fetchSaleItems, []);
  return (
    <div>
      <div>
        <SaleItemTable hits={saleItems} action={toggleAction} />
      </div>
    </div>
  );
};
export default NewQueue;
