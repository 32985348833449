import { useEffect, useState, useRef } from "react";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  useScreenClass,
  Visible
} from "react-grid-system";
import {Helmet} from "react-helmet";
import { WebHeader } from "../components/web_header";
import { formatPriceForDisplay } from "../util/display_formatting";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import style from "../styles/public.module.css";

const db = getFirestore();

const SaleItem = () => {
  const { id } = useParams();
  const [saleItem, setSaleItem] = useState<any>({});
  const [mainIndex, setMainIndex] = useState<number>(0);
  const carouselRef = useRef<any>();

  useEffect(() => {
    if (id) {
      const fetchSaleItem = async () => {
        const docSnap = await getDoc(doc(db, "SaleItem", id));

        if (docSnap.exists() && docSnap.data().status === 'active') {
          setSaleItem(docSnap.data());
        } else {
          setSaleItem({
            status: "not_found",
          });
        }
      };
      fetchSaleItem();
    }
  }, [id]);

  useEffect(() => {
    carouselRef.current.slideTo(mainIndex);
  }, [mainIndex])

  const screenClass = useScreenClass();
  const isLargeScreen = ["md", "lg", "xl", "xxl", "xxxl"].includes(screenClass);

  const handleDragStart = (e: any) => e.preventDefault();

  const getScaledImageUriFromSaleItemOriginalAndSize = (saleItem: any, originalUri: string, size: string) => {
    const fileNameArray = decodeURIComponent(originalUri).split('?')[0].split('/');
    const fileName = fileNameArray[fileNameArray.length - 1];
    if (saleItem?.images?.scaled[size][fileName]) {
      return saleItem?.images?.scaled[size][fileName];
    }
    return originalUri;
  };

  const getCarouselImageElementsFromSearchResultSaleItem = (saleItem: any, size: string) => {
    const uids = saleItem?.images?.original;
    if (uids) {
      const scaledUids = uids.map((uid: string) => getScaledImageUriFromSaleItemOriginalAndSize(saleItem, uid, size));
      return scaledUids.map((uid: string) => {
        return (<div key={`carousel-${uid}`} style={{
                  backgroundColor: '#F5F5F5',
                  width: '100%',
                  aspectRatio: '3/4',
                  borderRadius: 10,
                  overflow: 'hidden',
                  display: 'flex',
                  alignItems: 'center'}}>
                    <img style={{width: '100%'}} src={uid} onDragStart={handleDragStart} role="presentation" />
                </div>);
      });
    }
    return [];
  };

  const thumbs = () => {
    if (saleItem?.images?.original && saleItem?.images?.original.length > 1) {
      return saleItem.images.original.map((uri: any, i: number) => {
        return (<Visible md={true} lg={true} xl={true} xxl={true} key={uri}>
          <Col xs={0} md={3}>
            <div style={{
                width: '100%',
                aspectRatio: '3/4',
                backgroundImage: `url(${getScaledImageUriFromSaleItemOriginalAndSize(saleItem, uri, '600x600')})`,
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                marginBottom: 20,
                border: mainIndex === i ? '4px solid black' : 0,
              }}
              onClick={() => (setMainIndex(i), console.log(carouselRef.current.state.activeIndex))}
            />
          </Col>
          </Visible>);
      })
    }
    return [];
  };

  return (
    <div
      className={`${style.wrapper}`}
    >
      <Helmet>
        <title>{`${saleItem.title} | Offpanel`}</title>
      </Helmet>
      <Container style={{ height: "100%", padding: 20, maxWidth: 1140 }}>
        <Row style={{ height: isLargeScreen ? "100%" : undefined }}>
          {saleItem.status === "not_found" ? (
            <h1>Not found</h1>
          ) : (
            <>
              <Col style={{ height: isLargeScreen ? "100%" : undefined }}>
                  <WebHeader />
                  <Row style={{marginTop: 20, paddingBottom: 100}}>
                <Col xs={12} md={6}>
                  
                    <AliceCarousel
                      disableDotsControls={['md', 'lg', 'xl', 'xxl'].includes(screenClass) || saleItem?.images?.original.length === 1}
                      disableButtonsControls
                      mouseTracking
                      items={getCarouselImageElementsFromSearchResultSaleItem(saleItem, '600x600')}
                      ref={carouselRef}
                      activeIndex={mainIndex}
                    />

                    <Row style={{marginTop: 20}}>
                      {thumbs()}
                    </Row>

                </Col>
                <Col xs={12} md={6}>
                <h1
                      style={{
                        marginBottom: 16,
                        fontSize: 60,
                      }}
                    >
                      {saleItem.title}
                    </h1>
                    <div style={{fontWeight: 'bold', fontSize: 30}}>
                      {formatPriceForDisplay(saleItem.price)}
                    </div>
                    {saleItem.shipping && (<div style={{marginTop: 10}}>
                      Shipping: {saleItem.shipping}
                    </div>)}
                    {saleItem.description && (
                      <div style={{ marginBottom: 16, marginTop: 20, whiteSpace: 'pre-line' }}>
                        {saleItem.description}
                      </div>
                    )}
                    <a style={{textDecoration: 'none', color: 'black'}} href={`/profile/${saleItem?.username?.toLowerCase()}`}>
                    <div style={{marginTop: 40,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderTopColor: 'black',
                      borderTopWidth: 2,
                      borderBottomWidth: 2,
                      borderColor: 'black',
                      borderTop: 'gray 1.5px solid',
                      borderBottom: 'gray 1.5px solid',
                      padding: 20}}>
                      <div style={{
                        backgroundColor: 'lightgrey',
                        width: 50,
                        aspectRatio: '1/1',
                        borderRadius: 25,
                        backgroundImage: `url(${saleItem?.userimages?.avatar_100x100})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        display: 'inline-block'}} />
                      <div style={{display: 'inline-block', marginLeft: 10, fontSize: 25, fontWeight: '500', color: 'black'}}>{saleItem.username}</div>
                    </div>
                    </a>
                    <div style={{marginTop: 40}}>
                        {saleItem?.year && <div>Published: {saleItem.year}</div>}
                        {saleItem?.condition && <div style={{marginTop: 10}}>Condition: {saleItem.condition}</div>}
                        {saleItem?.firstAppearance && <div style={{marginTop: 10}}>First Appearance: {saleItem.firstAppearance}</div>}
                        {saleItem?.graded && <div style={{marginTop: 10}}>Graded: {saleItem.graded}</div>}
                        {saleItem?.issueNumber && <div style={{marginTop: 10}}>Issue Number: {saleItem.issueNumber}</div>}
                        {saleItem?.series && <div style={{marginTop: 10}}>Series: {saleItem.series}</div>}
                    </div>
                    <div
                  style={{
                    marginTop: 20,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <a href="https://apps.apple.com/us/app/offpanel-comic-marketplace/id1617929668">
                    <img src="/.well-known/images/app_store.svg" />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=co.offpanel.buttercup&hl=en_US&gl=US">
                    <img
                      src="/.well-known/images/google_play.png"
                      width="150"
                    />
                  </a>
                </div>
                <div
                  style={{
                    marginTop: 16,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Already have Offpanel? &nbsp;
                  <a href={`offpanel://saleitem/${id}`}>
                    Open {saleItem.title} in the app
                  </a>
                </div>
                </Col>
                </Row>
              </Col>
            </>
          )}

        </Row>
      </Container>
      </div>
  );
};

export default SaleItem;
