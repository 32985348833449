import { useEffect, useState } from "react";
import {
  getFirestore,
  query,
  collection,
  where,
  getDocs,
} from "firebase/firestore";
import { useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  useScreenClass,
} from "react-grid-system";
import style from "../styles/profile.module.css";
import {Helmet} from "react-helmet";
import dayjs from 'dayjs';
import {VerifiedBadge} from 'iconoir-react';
import { InstantSearch, Configure } from "react-instantsearch-dom";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import { envVars } from "../util/env";
import ProfileInfiniteHits from "../components/profile_infinite_hits";
import { WebHeader } from "../components/web_header";

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: envVars().TYPESENSE_API_KEY,
    nodes: [
      {
        host: envVars().TYPESENSE_HOST,
        port: 443,
        protocol: 'https',
      },
    ],
  },
  additionalSearchParameters: {
    query_by: 'title',
  },
});

const searchClient = typesenseInstantsearchAdapter.searchClient;


const db = getFirestore();





const Profile = () => {
  const { username } = useParams();
  const [userMeta, setUserMeta] = useState<any>({});

  useEffect(() => {
    if (username) {
      const fetchSaleItem = async () => {
        const q = query(
          collection(db, "UserMeta"),
          where("usernameLowercase", "==", username.toLowerCase())
        );

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          setUserMeta({...doc.data(), id: doc.id});
        });
      };
      fetchSaleItem();
    }
  }, [username]);

  const screenClass = useScreenClass();
  const isLargeScreen = ["lg", "xl", "xxl", "xxxl"].includes(screenClass);
  console.log(screenClass);
  return (
    <div
      className={`${style.wrapper}`}
    >
      <Helmet>
        <title>{`${userMeta.firstName} ${userMeta.lastName} (@${username})`}</title>
      </Helmet>
      <Container style={{ height: "100%", padding: 20, maxWidth: 1140 }}>
        <Row style={{ height: isLargeScreen ? "100%" : undefined }}>
          {userMeta.status === "not_found" ? (
            <h1>Not found</h1>
          ) : (
            <>
              <Col style={{ height: isLargeScreen ? "100%" : undefined }}>
                <WebHeader />
                <Row style={{marginTop: 20}}>
                <Col xs={4} md={4.5} lg={5}></Col>
                  <Col xs={4} md={3} lg={2}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    aspectRatio: '1/1',
                    position: 'relative',
                  }}
                >
                  <a href={`offpanel://profile/${username}`}>
                    <div style={{
                        borderRadius: "50%",
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        overflow: 'hidden',
                        backgroundImage: 
                          `url(${userMeta?.images?.avatar_600x600 ? userMeta?.images?.avatar_600x600 :
                            userMeta?.images?.avatar_200x200 ? userMeta?.images?.avatar_200x200 :
                            "/.well-known/images/placeholder.png"})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                      }}>
                    </div>
                  </a>
                </div>
                </Col>
                <Col xs={4} md={4.5} lg={5}></Col>
                </Row>
                <Row style={{textAlign: 'center'}}>
                  <Col xs={2} />
                <Col style={{paddingTop: 10}} xs={8}>
                <div
                    style={{
                      marginBottom: 10,
                      fontSize: 30,
                      fontWeight: '500',
                    }}
                  >
                    {userMeta?.firstName ? userMeta.firstName + ' ' + userMeta.lastName : null}
                  </div>
                  <div style={{marginBottom: 10, color: '#4b4b4b'}}>
                    {userMeta?.ctime ? `Member since ${dayjs(userMeta?.ctime?.toDate()).format('M/DD/YY')}` : null}
                  </div>
                  <div style={{marginBottom: 10}}>{userMeta.bio}</div>
                  {userMeta?.verified ?
                  <div style={{fontWeight: 500}}><VerifiedBadge strokeWidth={2} color="black" height={18} width={18} style={{verticalAlign: 'bottom'}} /> Verified Seller</div>: null}
                </Col>
                <Col xs={2} />
                </Row>
                <Row>
                  <Col xs={12}>
                    <InstantSearch indexName="saleitem" searchClient={searchClient}>
                      <Configure
                        filters={`seller:=${userMeta.id}&&status:=[active]`}
                      />
                      <ProfileInfiniteHits />
                    </InstantSearch>
                  </Col>
                </Row>
              </Col>
            </>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default Profile;
