import {Row, Col} from "react-grid-system";

  export const WebHeader = () => {
    return (
        <Row>
            <Col>
                <a href="/">
                <img src={"/.well-known/images/header_logo.png"} width={120} />
                </a>
            </Col>
        </Row>
    );
}