import { BrowserRouter, Routes, Route } from "react-router-dom";
import Admin from "./routes/admin";
import Categories from "./routes/categories";
import NewQueue from "./routes/new_queue";
import PublicHome from "./routes/public_home";
import SaleItem from "./routes/sale_item";
import PrivacyPolicy from "./routes/privacypolicy";
import Terms from "./routes/terms";
import Profile from "./routes/profile";

function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/*" element={<PublicHome />} />
          <Route path="/saleitem/:id" element={<SaleItem />} />
          <Route path="/profile/:username" element={<Profile />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/admin" element={<Admin />}>
            <Route path="/admin/categories" element={<Categories />} />
            <Route path="/admin/new_queue" element={<NewQueue />} />
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
